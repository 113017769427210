:root {
  --defaultWhiteColor: #ffff;
  --defaultPurple: #6825F7;
  --defaultPink: #C325FF;
  --defaultGrey: #696969;
  --defaultBlack: #000;
  --themeColor: #F5F5F5;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

@layer components {
  .auth_main {
    @apply min-h-screen;
  }

  .auth_form {
    @apply bg-white shadow-customShadow w-600;
  }

  .input_box {
    @apply bg-gray-100 h-10 border-black rounded-sm;
  }

  .gradient_button {
    @apply bg-gradient-to-r from-Purple to-Pink text-White w-56 h-10 text-lg font-bold;
  }
  .gradient_button:hover {
    @apply !bg-gradient-to-r !from-Pink !to-Purple !text-white !shadow-lg;
  }
  .outline_button{
    @apply border-Pink
  }
  .outline_button:hover{
    @apply border-Pink text-red-600
  }

  .content_style {
    @apply bg-ThemeColor h-[90vh] overflow-y-auto relative;
  }

  .header {
    @apply bg-White h-[100px] sticky top-0 z-50
  }

  .breadCrumps{
    @apply font-bold text-2xl 
  }
  .dashboard_card{
    @apply rounded-xl bg-White shadow-cardShadow h-[140px]
  }
}

.otp_container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.otp_input_container {
  margin-top: 0rem !important;
  margin: 0.8rem;
  padding: 1rem;
  width: 3.5rem !important;
  height: 3.5rem !important;
  font-size: 2rem;
  color: #1E1E1E;
  border: none !important;
  border-color: none !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0.2rem;
}

.toggling_sider {
  box-shadow: 5px 0px 10px #2653A20D;
  min-width: 225px !important;
}

.ant-form-item .ant-form-item-explain-error {
  text-align: left;
}

li.ant-menu-item.ant-menu-item-only-child {
  /* background-color: #dbdbdb; */
  margin: 0 0 0px !important;
  /* border-radius: 0 !important; */
  color: #000;
  height: 50px !important;
  font-size: 1rem;
  width: 100% !important;
}

.ant-menu-light .ant-menu-item-selected {
  color: var(--defaultWhiteColor) !important;
  background: linear-gradient(to right, var(--defaultPink), var(--defaultPurple));
  font-weight: 500;
  font-size: 1rem;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}

.ant-switch.ant-switch-checked {
  background: var(--defaultPurple) !important;
}
.ant-switch {
  background: #696969
}
.filter .ant-select-selector {
  border: 2px solid var(--defaultPink) !important;
  background: #ffffff;
}
.ant-select-selector {
  border: 1px solid var(--defaultBlack) !important;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}
.ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector{
  color: #000 !important;
}
.ant-image .ant-image-img{
  width: auto !important;
}